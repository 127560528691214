.chiTietBan {
    padding: 5px;

    form {
        background-color: white;
        padding: 10px;

        input {
            width: 100%;
            height: 34px;
            border: 1px solid silver;
            border-radius: 5px;
        }
    }

    .totalDetail {
        margin: 10px auto 15px;
        // height: 60px;
        padding: 10px;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        border-radius: 10px;
        position: relative;

        h3 {
            margin-bottom: 10px;
            // text-align: center;
        }

        .chiTietInfo {
            width: 50%;
            text-align: right;
            padding: 10px;
            border: 1px solid silver;
            border-radius: 5px;
            position: relative;


        }


        span {
            position: absolute;
            top: 10px;
            left: 10px;
            font-weight: bold;
            display: block;
            height: 21px;
        }

        i {
            color: silver;
            // margin-right: 5px;
            font-size: 24px;

        }

        p {
            font-size: 18px;
            font-weight: 600;
            padding: 3px 0;

            &.tong {}

            &.tra {
                color: #009900;
            }

            &.no {
                color: orangered;
            }
        }


    }

    h4 {
        text-align: right;
        font-size: 16px;
    }

    h4.no {
        color: orangered;
    }

    .chiTietItem {
        width: 404px;
        margin: 0 auto 15px;
        padding: 10px;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        position: relative;
        overflow: hidden;

        &:first-child {
            margin-top: 10px;

        }

        i.delete {
            // position: absolute;
            // top: 60px;
            // left: 10px;
            cursor: pointer;
            transition: all .3s;

            &:hover {
                color: red;
            }
        }


        p.ngay {
            color: silver;
        }

        p.giaoDich {
            color: #009900;
            font-weight: bold;
            text-transform: capitalize;
        }

        p.ghiChu {
            text-align: right;
            font-size: 16px;
            font-weight: 500;
            margin-top: 5px;
            color: red;
        }

        // .khachItem {
        //     width: 200px;
        //     overflow: hidden;
        //     display: flex;
        //     flex-direction: column;
        //     align-items: flex-start;
        //     justify-content: space-between;

        //     h3 {
        //         text-transform: capitalize;
        //         color: #009900;
        //         font-weight: bold;
        //     }


        //     p {
        //         font-size: 14px;
        //         font-weight: 600;
        //         color: silver;
        //         height: 21px;

        //         span {
        //             color: silver;
        //             font-size: 14px;
        //             font-size: 500;


        //         }
        //     }
        // }

        // .tienItem {
        //     width: 120px;
        //     text-align: right;
        //     display: flex;
        //     flex-direction: column;
        //     align-items: flex-end;
        //     justify-content: space-between;


        //     p {
        //         padding: 3px;
        //         font-size: 16px;
        //         font-weight: 500;
        //         height: 21px;

        //         &.tra {
        //             color: #009900;
        //         }

        //         &.no {
        //             color: orangered;
        //         }
        //     }
        // }

        // .voItem {
        //     width: 54px;
        //     text-align: right;
        //     display: flex;
        //     flex-direction: column;
        //     align-items: flex-end;
        //     justify-content: space-between;


        //     p {
        //         font-size: 16px;
        //         font-weight: 500;
        //         padding: 3px;
        //         height: 21px;

        //         &.tra {
        //             color: #009900;
        //         }

        //         &.no {
        //             color: orangered;
        //         }
        //     }

        // }

        .infoItem {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 2px;

            .ngay {
                width: 220px;
                height: 28px;
                text-align: left;
                display: flex;
                align-items: center;
                gap: 10px;


                p {
                    color: silver;
                    font-size: 14px;
                    font-weight: 600;

                    span {
                        color: silver;

                        font-size: 14px;
                        font-weight: 600;

                    }
                }

                h3 {
                    color: #009900;
                    text-transform: capitalize;
                }

                h3.tongNo {
                    color: orangered;
                    animation: colorChange .5s infinite alternate;
                }
            }

            .vo {
                width: 50px;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                font-weight: 600;

            }

            .tien {
                width: 110px;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                font-weight: 600;


            }

            .traVo {
                width: 50px;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                color: #009900;
                font-weight: 600;

            }

            .traTien {
                width: 110px;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                color: #009900;
                font-weight: 600;



            }
        }


    }

    .khachTraNo {
        h3 {
            color: orangered;
            text-align: center;
            margin-bottom: 15px;
        }
    }

    .traNoItem {
        background-color: white;
        margin-bottom: 15px;
        border-left: 2px solid orangered;

        padding: 10px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        position: relative;



        .title {
            display: flex;
            gap: 10px;

            p {
                color: silver;
                font-size: 14px;
                font-weight: 600;
            }
        }

        .khachHang {
            display: flex;
            padding: 0;
            gap: 10px;
            justify-content: space-between;
            align-items: center;

            h3 {
                text-transform: capitalize;
                color: #009900;
                margin-top: 10px;
            }




        }

        .traNoContent {
            margin-top: 10px;

            .traItem {
                display: flex;
                align-items: center;
                justify-content: space-between;

                p {
                    font-size: 16px;
                    font-weight: 500;

                }
            }
        }
    }

    .noData {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: whitesmoke;

        h1 {
            color: silver;
        }
    }



    @keyframes colorChange {
        from {
            color: orange;
        }

        to {
            color: red;
        }
    }
}