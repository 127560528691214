* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.orange {
    width: 20px;
    height: 80px;
    background-color: orange;
    position: absolute;
    top: 0;
    left: 20px;
}

.orangered {
    width: 20px;
    height: 140px;
    background-color: orangered;
    position: absolute;
    top: 0;
    left: 40px;
}

.black {
    width: 20px;
    height: 180px;
    background-color: black;
    position: absolute;
    top: 0;
    left: 60px;
}


.homepage {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);



    .introduce {
        text-align: center;
        width: 100%;
        height: 60px;
        display: flex;
        gap: 20px;

        flex-direction: column;

        justify-content: center;
        align-items: center;
        font-size: 20px;

        .typing-container {
            width: 100%;

            span {
                font-size: 38px;
                font-weight: 500;
                color: rgb(90, 90, 90);
            }

            span.hidden {
                display: none;
            }

            .cursor {
                display: inline-block;
                animation: blink 0.7s infinite;
                color: rgb(60, 60, 60);

            }
        }



    }

    button.dangNhap {
        padding: 10px 20px;
        background-color: #009900;
        color: white;
        font-size: 22px;
        font-weight: 600;
        border: none;
        cursor: pointer;
        transition: all .5s;

        &:hover {
            background-color: orangered;
        }
    }
}


@keyframes blink {

    0%,
    50% {
        opacity: 1;
    }

    50%,
    100% {
        opacity: 0;
    }
}