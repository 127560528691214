@media screen and (max-width: 500px) {


    .header {
        height: 50px;
        display: flex;
        align-items: center;
        background-color: orangered;
        padding: 0 20px;

        .icon {
            width: 30px;
            height: 30px;
            // background-color: orangered;
            text-align: center;
            line-height: 30px;

            i {
                color: white;
            }

        }

        .headerContent {
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            z-index: 1000;
            transform: translateX(-100%);
            width: 300px;
            transition: all .3s;
            background-color: white;
            padding: 0px 0 20px;
            box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
            background-color: whitesmoke;

            i.close {
                position: absolute;
                top: 15px;
                right: 15px;
                cursor: pointer;
                color: orangered;
                transition: all .3s;
                z-index: 1001;

                &:hover {
                    color: red;

                }
            }

            .user {
                margin-top: 20px;
                height: 60px;
                display: flex;
                padding: 20px;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 10px;


                h4 {
                    text-align: center;
                    color: orangered;
                    font-size: 22px;
                    display: inline-block;
                }

                i {
                    color: #009900;
                    font-size: 22px;
                }
            }



            ul {
                li {
                    list-style: none;
                    padding: 10px 20px;
                    transition: all .3s;
                    font-weight: 500;
                    font-size: 18px;
                    color: #009900;
                    cursor: pointer;
                    border-bottom: 1px solid #cccccc;

                    i {
                        margin-right: 5px;
                        color: #cccccc;
                        width: 25px;
                        font-size: 20px;
                    }


                    &:last-child {
                        border-bottom: none;

                    }



                    &:hover {
                        background-color: orangered;
                        color: white;
                        font-weight: 600;

                    }
                }
            }

            button {
                height: 40px;
                width: 280px;
                margin: 20px auto;
                border-radius: 10px;
                border: none;
                background-color: orangered;
                color: white;
                font-size: 16px;
                font-weight: bold;
                transition: all .3s;
                position: absolute;
                top: 460px;
                left: 50%;
                transform: translateX(-50%);

                &:hover {
                    background-color: red;

                }


            }
        }

    }

}