.traNoTool {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border: 1px solid silver;
    border-right: none;
    border-left: none;
    gap: 10px;
    // border-radius: 0 0 5px 5px;
    width: 100%;

    .traItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 10px;
        background-color: orangered;
        cursor: pointer;
        transition: all .5s;
        width: 90px;

        &:hover {
            background-color: #009900;
        }

        button {
            text-align: center;
            color: white;
            padding: 0 10px;
            background-color: transparent;
            border: none;
            font-size: 16px;
            font-weight: bold;


        }

    }

    .tienVoItem {
        width: 100%;

        .traTien {
            width: 100%;

            input {
                width: 100%;
                height: 30px;
                border: 1px solid silver;
                padding: 0 3px;
                border-radius: 5px;

            }
        }

        .traVo {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 5px;
            flex-wrap: wrap;
            width: 100%;


            .traVoItem {
                border: 1px solid silver;
                border-radius: 5px;

                padding: 0 0 0 3px;
                margin-top: 5px;

                p {
                    width: 60px;
                    height: 30px;
                }

                input {
                    width: 30px;
                    height: 28px;
                    margin-left: 5px;
                    border: none !important;
                }
            }
        }
    }
}