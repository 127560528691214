.doiTacItemWrap {
    // padding: 10px;
    margin-right: 10px;
    background-color: white;

    &:first-child {
        margin-left: 10px;
    }



    .doiTacItem {
        padding: 10px;
        border: 2px solid transparent;
        border-radius: 10px;
        width: 260px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        transition: all .5s;
        background-color: white;


        a {
            text-decoration: none;
        }

        i {
            color: silver;
        }

        h3 {
            color: #009900;
            font-size: 16px;
        }

        h4 {
            color: red;
        }
    }
}