.baoCao {
    h3 {
        // color: orangered;
        text-align: center;

    }

    h6 {
        color: silver;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        margin-top: 5px;
    }

    p {
        font-size: 16px;
        font-weight: bold;
        margin: 10px;

    }

    .baoCaoContent {
        margin-top: 5px;
        padding: 10px;

        .sumAll {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: absolute;
            top: 56px;
            right: 10px;
            height: 60px;
            width: 60px;


            background-color: #009900;
            border-radius: 50%;

            i {
                color: white;
            }

            span {
                font-size: 20px;
                font-weight: bold;
                color: white;
            }
        }

        h5.title {
            display: inline-block;
            background-color: #009900;
            color: white;
            border-radius: 0 15px 15px 0;
            margin: 20px 0 3px -10px;
            padding: 0 10px;
        }

        .baoCaoTienItem {
            margin: 30px auto 10px;
            padding: 22px 10px 20px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            text-align: center;
            display: flex;
            justify-content: space-between;
            position: relative;

            .title {
                display: inline-block;
                background-color: #009900;
                color: white;
                // border-radius: 0 15px 15px 0;
                position: absolute;
                top: -15px;
                left: -10px;
                padding: 5px 10px;

                h5 {
                    font-size: 16px;
                    font-weight: 500;
                }

            }


            div {
                color: #009900;

                p {
                    margin-bottom: 5px;
                    color: silver;
                    font-size: 14px;
                    font-weight: 500;



                }

                span {
                    text-align: right;
                    // color: orangered;
                    font-size: 20px;
                    font-weight: bold;

                }
            }


        }

        .baoCaoItem {
            margin: 30px auto 20px;
            padding: 22px 10px 10px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            text-align: center;
            display: flex;
            justify-content: space-between;
            position: relative;

            .title {
                display: inline-block;
                background-color: #009900;
                color: white;
                // border-radius: 0 15px 15px 0;
                position: absolute;
                top: -15px;
                left: -10px;
                padding: 5px 10px;

                h5 {
                    font-size: 16px;
                    font-weight: 500;
                }

            }


            div {
                color: #009900;

                p {
                    margin: 0;
                    color: silver;
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 5px;

                    i {
                        font-size: 22px;
                    }



                }

                span {
                    text-align: right;
                    color: orangered;
                    font-size: 20px;
                    font-weight: bold;

                }
            }


        }


        .baoCaoContentItem {
            margin: 30px auto 20px;
            padding: 30px 10px 10px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            text-align: center;
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            justify-content: flex-start;
            position: relative;

            .title {
                display: inline-block;
                background-color: #009900;
                color: white;
                // border-radius: 0 15px 15px 0;
                position: absolute;
                top: -15px;
                left: -10px;
                padding: 5px 10px;

                h5 {
                    font-size: 16px;
                    font-weight: 500;
                }

            }

            .item.vo {
                width: 86px;
                padding: 5px;
                border: 1px solid silver;
                border-radius: 10px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: column;

                h4 {
                    // color: #009900;
                    font-weight: 500;

                }

                .khoItem {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 5px;

                    p {
                        margin: 0;
                        font-weight: 600;
                        color: orangered;
                        font-size: 20px;
                        vertical-align: middle;



                    }

                    i {
                        color: silver;
                        font-size: 14px;
                    }
                }
            }

            .item.sanPham {
                width: 182px;
                padding: 10px;
                border: 1px solid silver;
                border-radius: 10px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: column;

                h4 {
                    // color: #009900;
                    font-weight: 500;

                }

                .khoItem {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 5px;
                    margin-top: 5px;

                    p {
                        margin: 0;
                        font-weight: 600;
                        color: orangered;
                        font-size: 20px;
                        vertical-align: middle;



                    }

                    i {
                        color: silver;
                        font-size: 14px;
                    }
                }
            }

        }
    }


}