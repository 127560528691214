.dangNhap {
    padding: 10px;

    .icon {
        text-align: center;

        i {
            font-size: 80px;
            color: orangered;
        }
    }




    h1 {
        text-align: center;
        color: orangered;
    }

    .dangNhapContent {
        max-width: 400px;
        margin: 20px auto;
        padding: 20px;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        form {
            .inputItem {
                margin-top: 5px;

                input {
                    height: 32px;
                    padding: 0 5px;
                }

                i.show {
                    margin-right: 3px;
                    cursor: pointer;
                    transition: all .5s;

                    &:hover {
                        color: orangered;

                    }
                }

            }

            button {
                margin: 20px auto;
                height: 34px;
                width: 100%;
                text-align: center;
                color: white;
                background-color: orangered;
                border: none;
                font-size: 18px;
                font-weight: bold;
                transition: all .5s;
                border-radius: 5px;
                cursor: pointer;

                &:hover {
                    background-color: #009900;
                }
            }
        }

        button.dangKyNgay {
            border: none;
            background-color: transparent;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
            color: #009900;
            transition: all .5s;

            &:hover {
                color: orangered;
            }
        }
    }
}