#footer {
    height: 40px;
    width: 100%;
    text-align: center;
    margin-top: 10px;
    // position: fixed;
    // bottom: 0;


    p {
        font-size: 12px;
        color: #cccccc;
        line-height: 30px;
    }
}