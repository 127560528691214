.timKiemWrap {
    padding: 5px;
    background-color: white;
    margin-top: 10px;

    .timKiem {
        display: flex;
        align-items: center;
        justify-content: space-between;

        gap: 10px;

        padding: 0 5px;
        border: 1px solid silver;
        border-radius: 5px;
        background-color: white;
        margin: 5px;

        i {
            color: silver;
        }

        input {
            height: 32px;
            width: 100%;
            background-color: transparent;
            border: none;

            &:focus {
                outline: none;
                border: none !important;
            }
        }
    }
}